import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/assets/utils/auth'
/* Layout */
import Layout from '@/layout/main-layout'
// 白名单，单token被清除后，跳转到登录页面或者指定页面
const whiteList = ['/login']

Vue.use(Router)

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noredirect           // 当设置 noredirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    noCache: true                // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/login',
    meta: {
      title: '登录',
      noCache: true
    },
    component: (resolve) => require(['@/views/login'], resolve),
    hidden: true
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: (resolve) => require(['@/views/redirect'], resolve)
      }
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/401'], resolve),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [{
      path: 'dashboard',
      name: 'Dashboard',
      component: () => import('@/views/dashboard/index'),
      meta: {
        title: '首页',
        icon: 'index',
        affix: true,
        noCache: true
      }
    }]
  },

  {
    path: '/profile',
    component: Layout,
    redirect: '/profile/index',
    hidden: true,
    children: [
      {
        path: 'index',
        component: () => import('@/views/profile/index'),
        name: 'Profile',
        meta: { title: '个人中心', icon: 'user', noCache: true }
      }
    ]
  },

  {
    path: '/system',
    component: Layout,
    redirect: 'noredirect',
    name: 'system',
    meta: {
      title: '系统管理',
      icon: 'lock',
      perms: 'sys:system:index'
    },
    children: [
      {
        path: 'role',
        name: 'role',
        component: () => import('@/views/system/role/index'),
        meta: {
          title: '角色管理',
          icon: 'role',
          perms: 'sys:system:role'
        }
      },
      {
        path: 'user',
        name: 'user',
        component: () => import('@/views/system/user/index'),
        meta: {
          title: '后台用户管理',
          icon: 'people',
          perms: 'sys:system:user'
        }
      },
      {
        path: 'customer',
        name: 'customer',
        component: () => import('@/views/system/customer/index'),
        meta: {
          title: '客户管理',
          icon: 'user',
          perms: 'sys:customer:user'
        }
      },
      {
        path: 'banner',
        name: 'banner',
        component: () => import('@/views/system/banner/index'),
        meta: {
          title: '轮播图管理',
          icon: 'image',
          perms: 'sys:system:banner'
        }
      },
      {
        path: 'set',
        name: 'set',
        component: () => import('@/views/system/set/index'),
        meta: {
          title: '系统配置',
          icon: 'fwb',
          perms: 'sys:system:set'
        }
      }
    ]
  },

  {
    path: '/car',
    component: Layout,
    redirect: 'noredirect',
    name: 'car',
    meta: { title: '车辆管理', icon: 'server', perms: 'car:model:index' },
    children: [
      {
        path: 'brand',
        name: 'brand',
        component: () => import('@/views/car/brand/index'),
        meta: { title: '品牌管理', icon: 'source', perms: 'car:brand:index' }
      },
      {
        path: 'series',
        name: 'series',
        component: () => import('@/views/car/series/index'),
        meta: { title: '系列管理', icon: 'chain', perms: 'car:series:index' }
      },
      {
        path: 'params',
        name: 'params',
        component: () => import('@/views/car/params/index'),
        meta: { title: '参数管理', icon: 'nested', perms: 'car:params:index' }
      },
      {
        path: 'info',
        name: 'info',
        component: () => import('@/views/car/info/index'),
        meta: { title: '车辆管理', icon: 'monitor', perms: 'car:info:index' }
      }
    ]
  },
  {
    path: '/competition',
    component: Layout,
    redirect: 'noredirect',
    name: 'competition',
    meta: {
      title: '竞价管理',
      icon: 'money',
      perms: 'car:competition:index'
    },
    children: [
      {
        path: 'price',
        name: 'price',
        component: () => import('@/views/competition/price/index'),
        meta: {
          title: '价格模块管理',
          icon: 'sys-tools',
          perms: 'car:competition:price'
        }
      },
      {
        path: 'car',
        name: 'car',
        component: () => import('@/views/competition/info/index'),
        meta: {
          title: '竞价车辆管理',
          icon: 'timing',
          perms: 'car:competition:info'
        }
      },
      {
        path: 'biddingRecord',
        name: 'biddingRecord',
        component: () => import('@/views/competition/biddingRecord/index'),
        meta: {
          title: '竞价记录管理',
          icon: 'log',
          perms: 'car:competition:biddingRecord'
        }
      }
    ]
  },

  // 404 page must be placed at the end !!!
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

const createRouter = () => new Router({
  // base: '/admin',
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
// const originalPush = Router.prototype.push
// Router.prototype.push = function push (location) {
//   return originalPush.call(this, location).catch(err => err)
// }

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

/**
 * 路由拦截过滤处理器
 */
NProgress.configure({ showSpinner: false })
// 处理登录成功后，访问登录页，自动跳转首页
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (getToken()) {
    /* has token */
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      // 当用户信息为空时，重新拉取用户信息 Object.keys 判断是否为空对象
      if (Object.keys(store.getters.user).length == 0) {
        store.dispatch('GetInfo').then(res => {
          if (res.code === 200) {
            next()
          } else {
            store.dispatch('FedLogOut').then(() => {
              // Message.error(res.msg)
              next({ path: '/' })
            })
          }
        }).catch(err => {
          store.dispatch('FedLogOut').then(() => {
            // Message.error(err)
            next({ path: '/' })
          })
        })
      } else {
        next()
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
