import request from '@/assets/utils/request'

// 验证码
export function getCodeImg (code) {
  return request({
    url: 'token/captcha',
    method: 'get',
    params: { code }
  })
}

// 登录
export function login (data) {
  return request({
    url: 'token/login',
    method: 'post',
    data: data
  })
}

// 获取当前登录用户信息
export function getInfo () {
  return request({
    url: 'sysUser/currentUserInfo',
    method: 'get'
  })
}

// 退出登录
export function logout (accessToken, refreshToken) {
  return request({
    url: 'token/logout',
    method: 'post',
    params: { accessToken: accessToken, refreshToken: refreshToken }
  })
}
